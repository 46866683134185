import { AntDesign, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { Button, Icon, IconButton, Image, Spinner, useTheme } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { Pressable, Text, TouchableOpacity, View } from "react-native";
import { useSelector } from "react-redux";

import { isDesktopScreen, Scale } from "../constants";
import { findFoodBySuggestedServingId, findMatchingSuggestedServing } from "../helpers/diaryHelpers";
import { getServingDescriptionText } from "../helpers/foodHelpers";
import { getRecipeMealImage } from "../helpers/generalHelpers";
import { getTestIDForRecipeMeal } from "../helpers/testHelpers";
import { doesOrganisationHaveManagedPlanning, getOrganisation } from "../helpers/userHelpers";
import styles from "../screens/DiaryViewScreenStyles";
import type {
  CalendarItemContentTypeEnum,
  CalendarItemStatusEnum,
  Meal,
  QuickAddMeal,
  RecipeMeal,
  SingleFoodMeal,
} from "../services/backendTypes";
import { FoodById, foodSelector } from "../slices/foodSlice";
import { userSelector, viewAsUserSelector } from "../slices/userSlice";

type CalendarItemComponentBaseProps = {
  onPress: () => void;
  onLongPress: () => void;
  // NOTE: This is kept because it may be used in the near future
  // eslint-disable-next-line react-redux/no-unused-prop-types
  testID?: string;
  onPressCalendarItemPlannedStatus?: () => void;
  editable?: boolean;
};

interface CalendarItemComponentProps extends CalendarItemComponentBaseProps {
  meal: Meal;
  status: CalendarItemStatusEnum;
  onSwap?: () => Promise<void>;
  contentType: CalendarItemContentTypeEnum;
  isLoading: boolean;
  editable?: boolean;
  onListAlternativesPress: () => void;
}

interface SingleFoodOrQuickAddMealPlannerItemProps extends CalendarItemComponentBaseProps {
  isEaten: boolean;
  title: string;
  description: string;
}

interface RecipeMealPlannerItemProps extends CalendarItemComponentBaseProps {
  isEaten: boolean;
  recipeMeal: RecipeMeal;
  isLoading: boolean;
  onSwap?: () => void;
  onPressCalendarItemPlannedStatus: () => void;
  onListAlternativesPress: () => void;
}

const CheckboxComponent = ({
  onPress,
  checked,
  testID,
}: {
  onPress: () => void;
  checked: boolean;
  testID?: string;
}): JSX.Element => {
  const theme = useTheme();

  const [submitted, setSubmitted] = React.useState<boolean>(false);

  const onPressCheckbox = (): void => {
    setSubmitted(true);
    onPress();

    setTimeout(() => {
      setSubmitted(false);
    }, 750);
  };

  return (
    // NOTE: The Checkbox does not update when the checked prop changes
    // (probably something to do with it being a controlled form component).
    // As such, we wrap it in a View for use in the tests
    <>
      {!submitted ? (
        <TouchableOpacity onPress={onPressCheckbox} testID={testID}>
          <MaterialCommunityIcons
            name={checked ? "checkbox-marked-circle" : "checkbox-blank-circle-outline"}
            size={36}
            color={checked ? theme.colors.primary["600"] : theme.colors.gray["700"]}
          />
        </TouchableOpacity>
      ) : (
        <Spinner size={36} />
      )}
    </>
  );
};

const RecipeMealPlannerItem = ({
  isEaten,
  recipeMeal,
  onPress,
  onSwap,
  onLongPress,
  onPressCalendarItemPlannedStatus,
  onListAlternativesPress,
  isLoading = false,
  editable = true,
}: RecipeMealPlannerItemProps): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <View
      style={{
        marginVertical: 10,
        borderRadius: 8,
        overflow: "hidden",
        position: "relative",
        borderColor: theme.colors.gray["200"],
        borderWidth: 1,
      }}
    >
      <Pressable onPress={onPress} onLongPress={onLongPress} testID={getTestIDForRecipeMeal(recipeMeal)}>
        <Image
          source={getRecipeMealImage(recipeMeal).source}
          resizeMode="cover"
          alt={"Recipe img"}
          width="100%"
          height={200}
          style={{ opacity: isEaten ? 0.5 : 1, minHeight: 200 }}
        />
        {isLoading ? (
          <View
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
            }}
          >
            <Spinner size="lg" color={theme.colors.primary["500"]} />
          </View>
        ) : null}
        <View
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            padding: 10,
          }}
        >
          <Text
            style={{
              fontWeight: "500",
              color: theme.colors.white,
              fontSize: 16,
              textDecorationLine: isEaten ? "line-through" : "none",
            }}
          >
            {recipeMeal.recipe_template.name}
          </Text>
          <Text style={{ color: theme.colors.white, fontSize: 14 }}>
            {`${Math.round(recipeMeal.kcal)} kcal • ${recipeMeal.recipe_template.preparation_time_min} min`}
          </Text>
        </View>
      </Pressable>

      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          borderTopWidth: 1,
          borderColor: theme.colors.gray["200"],
        }}
      >
        <View
          nativeID={`swap-recipeMeal-button-container-${recipeMeal.id}`}
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            borderRightWidth: 1,
            borderColor: theme.colors.gray["200"],
            paddingVertical: 5,
          }}
        >
          <IconButton
            onPress={onListAlternativesPress}
            icon={<Icon as={MaterialIcons} name="list" size="md" />}
            borderRadius="0"
            testID="choose-alternative-button"
          />
          <Text style={{ fontSize: 14, color: theme.colors.gray["500"], textAlign: "center", marginTop: 2 }}>
            {t("planner.alternatives_button")}
          </Text>
        </View>

        {/* Note: Commenting this for now since there is an error in the functionality. We will introduce it later */}
        {/* <View
          nativeID={`swap-recipeMeal-button-container-${recipeMeal.id}`}
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            borderRightWidth: 1,
            borderColor: theme.colors.gray["200"],
            paddingVertical: 5,
          }}
        >
          <IconButton
            onPress={onSwap}
            icon={<Icon as={AntDesign} name="sync" size="md" />}
            borderRadius="0"
            testID={`swap-recipeMeal-${recipeMeal.id}`}
          />
          <Text style={{ fontSize: 14, color: theme.colors.gray["500"], textAlign: "center", marginTop: 2 }}>Swap</Text>
        </View> */}

        {editable ? (
          <View
            style={{ flex: 1, alignItems: "center", justifyContent: "center", paddingVertical: 5 }}
            nativeID={`swap-recipeMeal-button-container-${recipeMeal.id}`}
          >
            <IconButton
              onPress={onPressCalendarItemPlannedStatus}
              icon={<Icon as={AntDesign} name={isEaten ? "checkcircle" : "checkcircleo"} size="md" />}
              borderRadius="0"
              testID={`calendarItem-${isEaten ? "EATEN" : "PLANNED"}-recipeMeal-${recipeMeal.id}`}
            />
            <Text style={{ fontSize: 14, color: theme.colors.gray["500"], textAlign: "center", marginTop: 2 }}>
              {isEaten ? t("planner.mark_as_planned") : t("planner.mark_as_eaten")}
            </Text>
          </View>
        ) : null}
      </View>
    </View>
  );
};

const SingleFoodOrQuickAddMealPlannerItem = ({
  isEaten,
  title,
  description,
  onLongPress,
  onPress,
  onPressCalendarItemPlannedStatus,
  testID,
  editable = true,
}: SingleFoodOrQuickAddMealPlannerItemProps): JSX.Element => {
  const theme = useTheme();
  return (
    <View style={{ flexDirection: "row", alignItems: "center", marginVertical: 8, paddingHorizontal: 10 }}>
      <Pressable
        onPress={onPress}
        onLongPress={onLongPress}
        style={{ flex: 1, flexDirection: "row", alignItems: "center" }}
        testID={testID}
        accessibilityRole="button"
      >
        <Text
          style={{
            fontWeight: "500",
            color: isEaten ? theme.colors.primary["300"] : theme.colors.gray["500"],
            textDecorationLine: isEaten ? "line-through" : "none",
            flex: 2,
          }}
        >
          {title}
        </Text>
        <Text
          style={{
            fontWeight: "500",
            color: isEaten ? theme.colors.primary["300"] : theme.colors.gray["500"],
            textDecorationLine: isEaten ? "line-through" : "none",
            flex: 1,
          }}
        >
          {description}
        </Text>
      </Pressable>
      {editable ? (
        <View style={{ marginHorizontal: 10 }}>
          <CheckboxComponent
            onPress={() => onPressCalendarItemPlannedStatus && onPressCalendarItemPlannedStatus()}
            checked={isEaten}
            testID={`calendarItem-${isEaten ? "EATEN" : "PLANNED"}-singleFoodMealOrQuickAddMeal-NO_ID_PROVIDED`}
          />
        </View>
      ) : null}

      <IconButton
        icon={<Icon as={MaterialIcons} color={"primary.600"} name="delete" />}
        onPress={onLongPress}
        accessibilityLabel="Delete item"
        style={{ marginHorizontal: 10 }}
      />
    </View>
  );
};

const CalendarItemComponent = ({
  meal,
  status,
  contentType,
  onPress,
  onSwap,
  onLongPress,
  onPressCalendarItemPlannedStatus,
  onListAlternativesPress,
  editable = true,
  isLoading = false,
}: CalendarItemComponentProps): JSX.Element | null => {
  const { t } = useTranslation();
  const foodsById: FoodById = useSelector(foodSelector);
  const checkIsEatenStatus = (statusValue: CalendarItemStatusEnum): boolean => statusValue === "EATEN";

  const createSingleFoodMealPlannerItem = (): JSX.Element | null => {
    const singleFoodMeal: SingleFoodMeal = meal as SingleFoodMeal;
    let suggestedServing = singleFoodMeal.ingredient.suggested_serving;
    let foodName = singleFoodMeal.ingredient.name;

    if (typeof suggestedServing === "number") {
      const suggestedServingId = suggestedServing;
      // NOTE: Food is sometimes undefined when diaryscreen loads
      const food = findFoodBySuggestedServingId(suggestedServingId, foodsById);
      if (food) {
        foodName = food.name;
        suggestedServing = findMatchingSuggestedServing(food, suggestedServingId);
      }
    }

    if (suggestedServing) {
      const { nonEditableDescription } = getServingDescriptionText(suggestedServing, singleFoodMeal.ingredient);

      return (
        <SingleFoodOrQuickAddMealPlannerItem
          isEaten={checkIsEatenStatus(status)}
          title={foodName}
          description={nonEditableDescription}
          onPress={onPress}
          onLongPress={onLongPress}
          onPressCalendarItemPlannedStatus={onPressCalendarItemPlannedStatus}
          testID={`planned-singleFoodMeal-${singleFoodMeal.id || "MISSING_ID"}`}
          editable={editable}
        />
      );
    }
    return null;
  };

  const createQuickAddMealPlannerItem = (): JSX.Element | null => {
    const quickAddMeal: QuickAddMeal = meal as QuickAddMeal;

    return (
      <SingleFoodOrQuickAddMealPlannerItem
        isEaten={checkIsEatenStatus(status)}
        title={t("planner.item.quick_calories_title")}
        description={`${quickAddMeal.kcal} ${t("general.kcal")}`}
        onPressCalendarItemPlannedStatus={onPressCalendarItemPlannedStatus}
        onPress={onPress}
        onLongPress={onLongPress}
        testID={`planned-quickAddMeal-${quickAddMeal.id}`}
        editable={editable}
      />
    );
  };

  switch (contentType) {
    case "singlefoodmeal":
      return createSingleFoodMealPlannerItem();
    case "recipemeal":
      // eslint-disable-next-line no-case-declarations
      const recipeMeal: RecipeMeal = meal as RecipeMeal;

      if (!onPressCalendarItemPlannedStatus) {
        throw new Error("onPressCalendarItemPlannedStatus is required for RecipeMeal CalendarItem");
      }

      return (
        <RecipeMealPlannerItem
          recipeMeal={recipeMeal}
          isEaten={checkIsEatenStatus(status)}
          onPress={onPress}
          onSwap={onSwap}
          isLoading={isLoading}
          onLongPress={onLongPress}
          onPressCalendarItemPlannedStatus={onPressCalendarItemPlannedStatus}
          onListAlternativesPress={onListAlternativesPress}
          editable={editable}
        />
      );
    case "quickaddmeal":
      return createQuickAddMealPlannerItem();
    default:
      return null;
  }
};

export default CalendarItemComponent;
