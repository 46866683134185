import { Image } from "native-base";
import React from "react";
import { SafeAreaView, StyleSheet, Text, TouchableOpacity, useWindowDimensions, View } from "react-native";
import { Button, Icon, useTheme } from "react-native-elements";
import { useSelector } from "react-redux";

import { Scale, VerticalScale } from "../../constants";
import { getOrganisation } from "../../helpers/userHelpers";
import { userSelector } from "../../slices/userSlice";

export interface Option {
  label: string;
  description: string;
  value: string;
  icon?: string;
  iconType?: string;
  testID?: string;
}

type IconProps = {
  name: string;
  type: string; // Optional, if not provided, it's an emoji
};

type SetSelectedOptionsFunctionType = (options: string[]) => void;

interface SelectionScreenProps {
  title: string;
  subtitle: string;
  options: Option[];
  multipleSelection?: boolean;
  selectedOptions: string[];
  setSelectedOptions: SetSelectedOptionsFunctionType;
  icon?: IconProps;
  onPressNext: () => void;
  onPressBack?: () => void;
  errorMessage?: string | string[];
  submitButtonLoading?: boolean;
}

const DEFAULT_ICON: IconProps = { name: "robot", type: "material-community" };

const SelectionScreen: React.FC<SelectionScreenProps> = ({
  title,
  subtitle,
  options,
  multipleSelection = false,
  selectedOptions,
  setSelectedOptions,
  icon = DEFAULT_ICON,
  onPressNext,
  onPressBack,
  errorMessage,
  submitButtonLoading = false,
}) => {
  const { width } = useWindowDimensions();
  const { theme } = useTheme();
  const primaryColor = theme.colors?.primary;
  const selectedOptionBackgroundColor = theme.colors?.searchBg;

  const user = useSelector(userSelector);
  const organisation = user ? getOrganisation(user) : undefined;
  const logoUri = organisation?.logo;

  const isDesktop = width >= 1024;

  const toggleSelection = (option: string): void => {
    if (multipleSelection) {
      const newSelectedOptions = selectedOptions.includes(option)
        ? selectedOptions.filter((item) => item !== option)
        : [...selectedOptions, option];

      setSelectedOptions(newSelectedOptions);
    } else {
      setSelectedOptions([option]);
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      <View style={[styles.header, isDesktop && styles.headerDesktop]}>
        {onPressBack ? (
          <Icon
            name="arrow-back"
            size={24}
            onPress={onPressBack}
            tvParallaxProperties={undefined}
            testID="back-button"
          />
        ) : null}
        {/* <Text style={[styles.progressText, { color: primaryColor }]}>10% Complete</Text> */}
      </View>
      <View style={[styles.content, isDesktop && styles.contentDesktop]}>
        {logoUri ? (
          <Image
            source={{ uri: logoUri }}
            style={{ width: Scale(160), height: VerticalScale(60) }}
            resizeMode="contain"
          />
        ) : (
          <Icon name={icon.name} type={icon.type} size={64} color={primaryColor} tvParallaxProperties={undefined} />
        )}
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.subtitle}>{subtitle}</Text>
        {options.map((option) => (
          <TouchableOpacity
            key={option.value}
            style={[
              styles.option,
              selectedOptions.includes(option.value) && {
                borderColor: primaryColor,
                backgroundColor: selectedOptionBackgroundColor,
              },
            ]}
            onPress={() => toggleSelection(option.value)}
            testID={option.testID || `selection-option-${option.value}`}
          >
            {option.icon && option.iconType ? (
              <Icon
                name={option.icon}
                type={option.iconType}
                size={24}
                color={selectedOptions.includes(option.value) ? primaryColor : "#333"}
                tvParallaxProperties={undefined}
              />
            ) : (
              <Text style={styles.emoji}>{option.icon}</Text>
            )}
            <View style={styles.optionTextContainer}>
              <Text style={[styles.optionLabel, selectedOptions.includes(option.value) && { color: primaryColor }]}>
                {option.label}
              </Text>
              <Text style={styles.optionDescription}>{option.description}</Text>
            </View>
            {selectedOptions.includes(option.value) && (
              <Icon
                name="check-circle"
                type="font-awesome-5"
                size={24}
                color={primaryColor}
                tvParallaxProperties={undefined}
              />
            )}
          </TouchableOpacity>
        ))}
        {errorMessage ? <Text style={styles.errorText}>{errorMessage}</Text> : null}
        <Button
          title="Next"
          buttonStyle={[styles.nextButton, { backgroundColor: primaryColor }]}
          onPress={onPressNext}
          loading={submitButtonLoading}
          testID="next-button"
        />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    padding: 16,
  },
  headerDesktop: {
    paddingHorizontal: "20%",
  },
  progressText: {
    marginLeft: 16,
    fontWeight: "bold",
  },
  content: {
    flex: 1,
    alignItems: "center",
    padding: 16,
  },
  contentDesktop: {
    paddingHorizontal: "20%",
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 16,
    textAlign: "center",
  },
  subtitle: {
    fontSize: 16,
    color: "#777",
    textAlign: "center",
    marginVertical: 16,
  },
  option: {
    flexDirection: "row",
    alignItems: "center",
    padding: 16,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#ddd",
    marginVertical: 8,
    width: "100%",
    maxWidth: 600,
  },
  optionTextContainer: {
    flex: 1,
    marginLeft: 16,
  },
  optionLabel: {
    fontSize: 18,
    fontWeight: "bold",
  },
  optionDescription: {
    fontSize: 14,
    color: "#777",
  },
  errorText: {
    color: "red",
    marginTop: 8,
    textAlign: "center",
  },
  nextButton: {
    paddingVertical: 12,
    paddingHorizontal: 32,
    marginTop: 16,
    borderRadius: 8,
    width: "80%", // Make the button wider
    alignSelf: "center", // Center the button horizontally
  },
  emoji: {
    fontSize: 24,
  },
});

export default SelectionScreen;
