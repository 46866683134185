import { useActionSheet } from "@expo/react-native-action-sheet";
import { MaterialIcons } from "@expo/vector-icons";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import _ from "lodash";
import { Center, Icon, IconButton, Spinner, useTheme } from "native-base";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, Image, Modal, SafeAreaView, ScrollView, Text, TouchableOpacity, View } from "react-native";
import { useSelector } from "react-redux";
import * as URI from "uri-js";

import { store } from "../../../store";
import { CommonCategoryCard, CommonSearchInput, CommonSelect } from "../commons";
import CommonHeaderDivider from "../commons/CommonHeaderDivider";
import RecipeMealCard from "../components/RecipeCard";
import { commonStyles, Images, Routes, Scale, width } from "../constants";
import { MealType, RecipeCategory, RecipeTag, SourceProvider } from "../constants/data";
import { addMealToPlanner, generateMealsPrefetchOptions, getPlannerData } from "../helpers/diaryHelpers";
import { formatMealType } from "../helpers/generalHelpers";
import { FeatureFlag, getOrganisation, isFeatureFlagEnabled } from "../helpers/userHelpers";
import type { RecipeSearchScreenParams, RootStackParamList } from "../navigation/NavigationStackParams";
import backendApi from "../services/backendApi";
import type {
  FoodGenerateMealsListApiArg,
  GeneratedRecipeMeal,
  MealTypeEnum,
  RecipeMeal,
  RecipeMealRequest,
  RecipeTemplate,
  RecipeTemplateRequest,
} from "../services/backendTypes";
import { api } from "../services/baseApi";
import logger from "../services/logger";
import { userSelector, viewAsUserSelector } from "../slices/userSlice";
import type { CategoryFilter, RecipeMacrosItemType, RecipeTagOrCategory } from "../types";
import styles from "./RecipeSearchScreenStyle";

// NOTE: There is a scoping problem with usePrefetch, I don't know why
// eslint-disable-next-line @typescript-eslint/unbound-method
const {
  useFoodRecipeMealCreateMutation,
  useFoodGenerateMealsListQuery,
  usePlannerCalendarItemCreateMutation,
  usePlannerPlanGeneratedRecipeMealCreateMutation,
  usePlannerCalendarItemDestroyMutation,
} = backendApi;

const RECIPE_TAG_TO_NAME = {
  [RecipeTag.BREAD]: "Bread",
  [RecipeTag.BURGER]: "Burger",
  [RecipeTag.COUSCOUS_AND_QUINOA]: "Couscous and quinoa",
  [RecipeTag.DAIRY]: "Dairy",
  [RecipeTag.DIPS_AND_SPREADS]: "Dips and spreads",
  [RecipeTag.KETO]: "Keto",
  [RecipeTag.PANCAKES_AND_OMELETTES]: "Pancakes and omelettes",
  [RecipeTag.PASTA]: "Pasta",
  [RecipeTag.POTATO]: "Potato",
  [RecipeTag.RICE_AND_NOODLES]: "Rice and noodles",
  [RecipeTag.SALAD]: "Salad",
  [RecipeTag.SNACKS_AND_BAKES]: "Snacks and cakes",
  [RecipeTag.SOUP]: "Soup",
  [RecipeTag.VEGAN]: "Vegan",
  [RecipeTag.WRAP]: "Wrap",
};

const TAG_CATEGORY_LIST: CategoryFilter[] = (Object.keys(RecipeTag) as Array<keyof typeof RecipeTag>).map((tag, i) => ({
  id: `${i}_tag`,
  label: RECIPE_TAG_TO_NAME[RecipeTag[tag]],
  value: RecipeTag[tag],
  image: Images.CategoryBrood, // FIXME: use appropriate image
}));

enum SelectedMealPreparationTime {
  LTE_5 = "5 min. or less",
  LTE_10 = "10 min. or less",
  LTE_15 = "15 min. or less",
  LTE_20 = "20 min. or less",
  LTE_30 = "30 min. or less",
  GTE_30 = "30 min. or more",
  ALL = "All preparation times",
}

const SELECTED_MEAL_PREPARATION_TIME_TO_QUERY_ARGS = {
  [SelectedMealPreparationTime.LTE_5]: { preparationTimeMinLte: 5 },
  [SelectedMealPreparationTime.LTE_10]: { preparationTimeMinLte: 10 },
  [SelectedMealPreparationTime.LTE_15]: { preparationTimeMinLte: 15 },
  [SelectedMealPreparationTime.LTE_20]: { preparationTimeMinLte: 20 },
  [SelectedMealPreparationTime.LTE_30]: { preparationTimeMinLte: 30 },
  [SelectedMealPreparationTime.GTE_30]: { preparationTimeMinGte: 30 },
  [SelectedMealPreparationTime.ALL]: undefined,
};

// TODO: Put these in a data file
const sampleMacrosForDisplay: RecipeMacrosItemType[] = [
  {
    macroName: "kcal",
    progress: 630,
    total: 716,
    unit: "kcal",
  },
  {
    macroName: "protein",
    progress: 8,
    total: 18,
    unit: "g",
  },
  {
    macroName: "fat",
    progress: 23,
    total: 27,
    unit: "g",
  },
  {
    macroName: "carbohydrates",
    progress: 16,
    total: 33,
    unit: "g",
  },
];

type ModalContentProps = {
  modalizeRef: React.Ref<Modal>;
};

type RecipeMealSearchByPageInternal = { [number: number]: GeneratedRecipeMeal[] };
type RecipeMealSearchByPage = { byPage: RecipeMealSearchByPageInternal };

type Props = {
  navigation: NativeStackNavigationProp<RootStackParamList, Routes.RecipeSearchScreen>;
  route: {
    params: RecipeSearchScreenParams;
  };
};

const recipeMealSearchInitialState = { byPage: {} };

const MemoizedRecipeMealCard = React.memo(
  RecipeMealCard,
  (prevProps, nextProps) => prevProps.recipeMeal.recipe_template.id === nextProps.recipeMeal.recipe_template.id
);

const GENERATE_MEALS_ENDPOINT_NAME = "foodGenerateMealsList";

const RecipeSearchScreen = ({
  navigation,
  route: {
    params: { mealSlotSpecification, currentCalendarDay, calendarItemToReplace },
  },
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const CATEGORY_TO_NAME = {
    [RecipeCategory.POPULAR]: "Popular",
    [RecipeCategory.FAVOURITE]: t("recipe_search.category_favourite"),
    [RecipeCategory.OWN_RECIPE]: t("recipe_search.category_own"),
  };

  const POPULAR_CATEGORY = {
    id: `${1}_category`,
    label: CATEGORY_TO_NAME[RecipeCategory.POPULAR],
    value: RecipeCategory.POPULAR,
    image: Images.CategoryMostChosen,
  };
  const FAVOURITE_CATEGORY = {
    id: `${2}_category`,
    label: CATEGORY_TO_NAME[RecipeCategory.FAVOURITE],
    value: RecipeCategory.FAVOURITE,
    image: Images.CategoryFavourite,
  };
  const OWN_MEAL_CATEGORY = {
    id: `${3}_category`,
    label: CATEGORY_TO_NAME[RecipeCategory.OWN_RECIPE],
    value: RecipeCategory.OWN_RECIPE,
    image: Images.CategoryOwnRecipe,
  };

  const CATEGORY_LIST = [POPULAR_CATEGORY, FAVOURITE_CATEGORY, OWN_MEAL_CATEGORY];

  // NOTE: We are not showing these filters because they are out of scope for the current release
  const CATEGORY_FILTERS = [...CATEGORY_LIST, ...TAG_CATEGORY_LIST];

  const theme = useTheme();

  const MEAL_TYPE_LIST = (Object.keys(MealType) as Array<keyof typeof MealType>).map((mealType, i) => ({
    id: i,
    label: formatMealType(MealType[mealType as MealTypeEnum], t),
    value: MealType[mealType],
  }));

  const viewAsUser = useSelector(viewAsUserSelector);
  const realUser = useSelector(userSelector);
  const user = viewAsUser || realUser;
  if (!user) {
    throw new Error("User is not set");
  }

  const organisation = getOrganisation(user);
  const productLoggingIsDisabled = isFeatureFlagEnabled(organisation || null, FeatureFlag.DisableProductLogging);
  const recipeTemplateEditable = !productLoggingIsDisabled;
  const ownMealsAvailable = !productLoggingIsDisabled;

  const FILTERS_TO_SHOW = ownMealsAvailable ? [FAVOURITE_CATEGORY, OWN_MEAL_CATEGORY] : [FAVOURITE_CATEGORY];

  const [page, setPage] = useState<number>(1);
  const [recipeMealsByPage, setRecipeMealsByPage] = useState<RecipeMealSearchByPage>(recipeMealSearchInitialState);
  const [searchText, setSearchText] = useState("");
  const [selectedTags, setSelectedTags] = useState<RecipeTag[]>([]);
  const [selectedMealType, setSelectedMealType] = useState<MealType>(MealType[mealSlotSpecification.meal_type]);
  const [selectedMealPreparationTime, setSelectedMealPreparationTime] = useState<SelectedMealPreparationTime>(
    SelectedMealPreparationTime.ALL
  );
  const [selectFavourites, setSelectFavourites] = useState<boolean>(false);
  const [sourceProvider, setSourceProvider] = useState<SourceProvider[]>([SourceProvider.WEEKMEALS]);
  const [ownRecipeModalVisible, setOwnRecipeModalVisible] = useState(false);
  const { showActionSheetWithOptions } = useActionSheet();
  const setRecipeMealSearch = ({ results }: { results: GeneratedRecipeMeal[] }): void => {
    setRecipeMealsByPage({
      byPage: {
        ...recipeMealsByPage.byPage,
        [page]: results,
      },
    });
  };

  // NOTE: This is here to set the state of the search results dirty so we can use useEffect properly
  const [searchCounter, setSearchCounter] = useState<number>(0);
  const resetPage = (): void => {
    setPage(1);
    setSearchCounter(searchCounter + 1);
  };

  const resetRecipeMealSearch = (): void => {
    resetPage();
    setRecipeMealsByPage(recipeMealSearchInitialState);
  };

  let mealGenerateQueryArgs: FoodGenerateMealsListApiArg = {
    mealSlotSpecification: mealSlotSpecification.id,
    ingredientSearch: searchText,
    favourite: selectFavourites,
    sourceProvider,
    tags: selectedTags,
    page,
    user: user.id,
    kcal: mealSlotSpecification.kcal || 0, // NOTE: this is included only for cache busting
    protein: mealSlotSpecification.protein || 0, // NOTE: this is included only for cache busting
  };

  if (selectedMealPreparationTime) {
    mealGenerateQueryArgs = {
      ...mealGenerateQueryArgs,
      ...SELECTED_MEAL_PREPARATION_TIME_TO_QUERY_ARGS[selectedMealPreparationTime],
    };
  }

  if (sourceProvider.includes(SourceProvider.USER_GENERATED) && user) {
    mealGenerateQueryArgs.author = user.id;
  }

  if (selectedMealType !== MealType.ALL) {
    mealGenerateQueryArgs.mealTypes = [selectedMealType];
  }

  const allFoodSearchArgs = [
    selectedTags,
    selectedMealType,
    selectedMealPreparationTime,
    selectFavourites,
    sourceProvider,
  ];

  useEffect(() => {
    resetRecipeMealSearch();
  }, allFoodSearchArgs);

  const {
    data,
    error,
    isLoading: isLoadingFirstTime,
    isFetching,
  } = useFoodGenerateMealsListQuery(mealGenerateQueryArgs);

  // Prefetching
  const onFirstPage = page === 1;
  const moreDataAvailable = Boolean(data?.next);
  const onSubsequentPageButMoreDataAvailable = moreDataAvailable && page > 1;
  const searchingForWeekmealsRecipes = sourceProvider.includes(SourceProvider.WEEKMEALS);
  const searchQueryEmpty = searchText === "";
  const searchingForOriginalMealType = selectedMealType === MealType[mealSlotSpecification.meal_type];
  const searchingForOriginalPrepTime = selectedMealPreparationTime === SelectedMealPreparationTime.ALL;

  const isOriginalQuery =
    !selectFavourites &&
    searchingForWeekmealsRecipes &&
    searchQueryEmpty &&
    searchingForOriginalMealType &&
    searchingForOriginalPrepTime;

  const shouldPrefetchNextPage =
    moreDataAvailable && Boolean((onFirstPage && isOriginalQuery) || onSubsequentPageButMoreDataAvailable);

  const pageToPrefetch = shouldPrefetchNextPage ? page + 1 : page;

  // NOTE: We do the prefetch off the critical path to speed up load times
  useEffect(() => {
    if (!shouldPrefetchNextPage) return;

    const prefetchRequestOptions = {
      ...mealGenerateQueryArgs,
      page: pageToPrefetch,
    };

    store.dispatch(
      // The compiler is wrong here
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      api.util.prefetch(GENERATE_MEALS_ENDPOINT_NAME, prefetchRequestOptions, generateMealsPrefetchOptions)
    );
  }, [shouldPrefetchNextPage]);

  useEffect(() => {
    if (!isFetching && data?.results) {
      setRecipeMealSearch({ results: data.results });
    }
  }, [data, searchCounter]);

  const onPressBack = (): void => {
    navigation.goBack();
  };

  const onSearchTextChange = (value: string): void => {
    resetPage();
    setSearchText(value);
  };

  const onCategoryCardChange = (value: RecipeCategory | RecipeTag): void => {
    logger.debug("onCategoryCard", value);
    resetPage();

    switch (value) {
      case RecipeCategory.FAVOURITE: {
        setSelectFavourites(!selectFavourites);
        return;
      }
      case RecipeCategory.OWN_RECIPE: {
        let updatedSourceProvider = [SourceProvider.USER_GENERATED];
        if (sourceProvider.includes(SourceProvider.USER_GENERATED)) {
          updatedSourceProvider = [];
        }

        setSourceProvider(updatedSourceProvider);
        return;
      }
      case RecipeCategory.POPULAR: {
        setSelectedTags([]);
        return;
      }
      default: {
        // This is a RecipeTag
        setSelectedTags([value]);
      }
    }
  };

  const [createCalendarItemFromGeneratedMeal] = usePlannerPlanGeneratedRecipeMealCreateMutation();
  const [createRecipeMealFromRecipeTemplate] = useFoodRecipeMealCreateMutation();

  const [deleteCalendarItemOnBackend] = usePlannerCalendarItemDestroyMutation();

  const [createCalendarItem] = usePlannerCalendarItemCreateMutation();
  const { refetchCalendarDays } = getPlannerData();

  const addRecipeMealToCalendar = async (recipeMeal: RecipeMeal): Promise<void> => {
    await addMealToPlanner({
      currentCalendarDay,
      mealSlot: mealSlotSpecification,
      mealContentType: "recipemeal",
      meal: { ...recipeMeal, content_type: "RecipeMeal", id: recipeMeal.id },
      createCalendarItem,
      refetchCalendarDays,
    });

    refetchCalendarDays();
  };

  const createRecipeMealFromRecipeTemplateWrapper = async (
    recipeTemplate: RecipeTemplate,
    doNotPlan = false
  ): Promise<void> => {
    const recipeTemplateWithImageRemoved = _.omit(recipeTemplate, ["image"]);
    const recipeTemplateRequest: RecipeTemplateRequest = recipeTemplateWithImageRemoved;
    const recipeMealRequest: RecipeMealRequest = {
      recipe_template: recipeTemplateRequest,
      portions: 1,
    };

    const recipeMeal = await createRecipeMealFromRecipeTemplate({
      recipeMealRequest,
    }).unwrap();

    if (!doNotPlan) {
      await addRecipeMealToCalendar(recipeMeal);
    }

    navigation.pop(1);
  };

  const createRecipeMealFromGeneratedMealWrapper = async (
    generatedRecipeMeal: GeneratedRecipeMeal,
    portions: number
  ): Promise<void> => {
    if (!currentCalendarDay.id) {
      throw new Error("currentCalendarDay is not set");
    }

    if (calendarItemToReplace) {
      // NOTE: Since this is used for the "list alternates" feature
      // if there is a calendarItemToReplace, we need to delete it first
      await deleteCalendarItemOnBackend({ id: calendarItemToReplace });
    }

    // NOTE: To speed this up even further this should return the updated CalendarDay on success
    await createCalendarItemFromGeneratedMeal({
      planGeneratedRecipeMealRequest: {
        ...generatedRecipeMeal,
        portions_to_plan: portions,
        // NOTE: we need to remove generatedRecipeMeal.recipe_template.image, note the
        // difference between RecipeTemplateRequest.image (blob) and RecipeTemplate.image (string)
        recipe_template: _.omit(generatedRecipeMeal.recipe_template, "image"),
        calendar_day_id: currentCalendarDay.id,
        meal_slot_specification_id: mealSlotSpecification.id,
      },
    });

    // NOTE: We do 2 here because there is the RecipeSearch screen also in the stack
    navigation.pop(2);
  };

  const onRecipeMealPress = (generatedRecipeMeal: GeneratedRecipeMeal): void => {
    navigation.push(Routes.AddRecipeTemplateStack, {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      screen: Routes.RecipeDetailScreen,
      params: {
        recipeMeal: generatedRecipeMeal,
        mealSlotSpecification,
        mealType: selectedMealType,
        recipeTemplateEditable,
        onSelect: (portions: number) => createRecipeMealFromGeneratedMealWrapper(generatedRecipeMeal, portions),
      },
    });
  };

  const onOpenActionSheet = (): void => {
    const options = ["Edit", "Remove", "Cancel"];
    const editBtnIndex = 0;
    const removeButtonIndex = 1;
    const cancelButtonIndex = 2;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex: editBtnIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === editBtnIndex) {
          logger.debug("Pressed Edit mode");
        } else if (buttonIndex === removeButtonIndex) {
          logger.debug("Pressed Remove mode");
        }
        // Do something here depending on the button index selected
      }
    );
  };

  const ownMealsFilterSelected =
    sourceProvider?.includes(SourceProvider.USER_GENERATED) && sourceProvider?.length === 1;

  // TODO: These should be in a helpers file when this file is refactored
  const isARecipeTag = (rt: RecipeTagOrCategory): rt is RecipeTag =>
    Object.values(RecipeTag).includes(rt as unknown as RecipeTag);
  const isARecipeCategory = (rc: RecipeTagOrCategory): rc is RecipeCategory =>
    Object.values(RecipeCategory).includes(rc as unknown as RecipeCategory);

  const isPopularSelected = selectedTags === undefined;

  const isCategoryFilterSelected = (category: CategoryFilter): boolean => {
    const isMyCategorySelected = isARecipeTag(category.value) && selectedTags?.includes(category.value);

    return (
      isMyCategorySelected ||
      (selectFavourites ? category.value === RecipeCategory.FAVOURITE : false) ||
      (isPopularSelected && !selectFavourites && !ownMealsFilterSelected
        ? category.value === RecipeCategory.POPULAR
        : false) ||
      (ownMealsFilterSelected ? category.value === RecipeCategory.OWN_RECIPE : false)
    );
  };

  const createCategoryCard = (category: CategoryFilter): JSX.Element => (
    <CommonCategoryCard
      key={category.id}
      category={category}
      isSelected={isCategoryFilterSelected(category)}
      onPressCard={() => {
        const selectedCategory = isARecipeCategory(category.value)
          ? RecipeCategory[category.value]
          : RecipeTag[category.value];
        onCategoryCardChange(selectedCategory);
      }}
    />
  );

  const headerComponent = React.useMemo(
    () => (
      <View style={commonStyles.container}>
        <View style={styles.pageHeader}>
          <IconButton
            mr={0}
            pr={0}
            onPress={onPressBack}
            icon={<Icon as={MaterialIcons} name="arrow-back-ios" color={"gray.500"} />}
          />
          <Text style={[commonStyles.titleText24, { marginLeft: Scale(20) }]}>
            {formatMealType(mealSlotSpecification.meal_type, t)}
          </Text>
        </View>

        <CommonHeaderDivider />

        <View style={commonStyles.container}>
          {/* NOTE: It is not advised to put a ScrollView (ie, FlatList) inside another ScrollView
          As such this is disabled for now. */}
          {/* <ScrollView contentContainerStyle={commonStyles.paddingContainer}> */}
          <View style={commonStyles.paddingContainer}>
            <View style={{ paddingTop: Scale(10) }}>
              <CommonSearchInput
                placeholder={t("recipe_search.search_prompt")}
                onChangeText={onSearchTextChange}
                leftIcon={<Icon as={MaterialIcons} mx={"2"} size={"md"} color={"primary.600"} name="search" />}
                testID={"recipe-search-input"}
              />
            </View>
            <TouchableOpacity
              style={styles.createRecipe}
              onPress={() => {
                navigation.push(Routes.RecipeAddOwnStack, {
                  screen: Routes.RecipeAddOwnScreen,
                  params: {
                    mealSlotSpecification,
                    onSave: async (recipeTemplate: RecipeTemplate, doNotPlan?: boolean): Promise<void> => {
                      await createRecipeMealFromRecipeTemplateWrapper(recipeTemplate, doNotPlan);
                      navigation.goBack();
                    },
                    submitMessage: t("recipe_create.save_as_meal_button_text", {
                      meal: formatMealType(mealSlotSpecification.meal_type, t),
                    }),
                  },
                });
              }}
              testID={"createownrecipe-button"}
            >
              {ownMealsAvailable ? (
                <View>
                  <Icon as={MaterialIcons} mx={"2"} size={"md"} name="add" />
                  <Text style={[commonStyles.titleText16, { color: theme.colors.gray["600"] }]}>
                    {t("planner.create_your_own_recipe_button_text")}
                  </Text>
                </View>
              ) : null}
            </TouchableOpacity>
            {/* NOTE: The filters (except own meals) are not in scope for the initial release */}
            {/* <ScrollView horizontal style={{ marginVertical: VerticalScale(25) }}>
            {CATEGORY_FILTERS.map(createCategoryCard)}
          {/* NOTE: Only offer the own meal (and favourites) filter */}
            <ScrollView horizontal>{FILTERS_TO_SHOW.map(createCategoryCard)}</ScrollView>
            {/* NOTE: This is not required because we do not use the RecipeTag filters at the moment */}
            <View style={{ flexDirection: "row", marginTop: Scale(10) }}>
              <CommonSelect
                key="meal_type"
                width={width * 0.4}
                optionsList={MEAL_TYPE_LIST}
                selectedValue={selectedMealType}
                placeholder={t("recipe_search.meal_type_filter_text")}
                onValueChange={(itemValue) => setSelectedMealType(MealType[itemValue as MealTypeEnum])}
                testID="meal-type-select"
              />

              <CommonSelect
                key="preparation_time"
                optionsList={(
                  Object.keys(SelectedMealPreparationTime) as Array<keyof typeof SelectedMealPreparationTime>
                ).map((value, index) => ({
                  id: index,
                  label: t(`recipe_search.preparation_time_filter_options.${value}`),
                  value,
                }))}
                selectedValue={selectedMealPreparationTime || ""}
                placeholder={t("recipe_search.preparation_time_filter_text")}
                onValueChange={(itemValue) => {
                  setSelectedMealPreparationTime(itemValue as SelectedMealPreparationTime);
                }}
                testID={"preparationTime-select"}
                width={width * 0.4}
              />
            </View>

            {!ownMealsFilterSelected ? (
              <View style={{ flexDirection: "row", marginTop: Scale(10) }} testID="macroFittedRecipesMessage-div">
                <Image source={Images.TickIcon} style={styles.tickIcon}></Image>
                <Text style={{ color: theme.colors.blue["400"], flexWrap: "wrap", marginLeft: Scale(10), flex: 1 }}>
                  {t("recipe_search.autofitted_macros_message")}
                </Text>
              </View>
            ) : (
              <View style={{ marginTop: Scale(10) }}></View>
            )}

            <View style={styles.recipesListContainer}></View>
          </View>
        </View>
      </View>
    ),
    [
      selectedMealType,
      selectedMealPreparationTime,
      selectedTags,
      selectFavourites,
      sourceProvider,
      // NOTE: The below should not be necessary
      // onPressBack,
      // mealSlotSpecification,
      // t,
      // onSearchTextChange,
      // createCategoryCard,
      // MEAL_TYPE_LIST,
      // navigation,
      // createRecipeMealFromRecipeTemplateWrapper,
    ]
  );

  const searchResultsList = _.map(Object.values(recipeMealsByPage.byPage).flat(), (recipeMeal) => ({
    ...recipeMeal,
    key: recipeMeal.recipe_template.id,
  }));

  const loadingAnotherPage = !isLoadingFirstTime && isFetching && page > 1;

  /**
   * This safely parses the next page value and then sets the next page.
   *
   * Note: If we just did setPage(page + 1) then we could get into a loop
   * due to the scroll constantly triggering `onEndReachedThreshold` - this logic prevents that
   *
   */
  const onEndReachedFunc = (): void => {
    if (data?.next) {
      const parsedNextUrl = URI.parse(data.next);

      if (parsedNextUrl.query) {
        const parsedUrlQuery = queryString.parse(parsedNextUrl.query);

        if (parsedUrlQuery.page && typeof parsedUrlQuery.page === "string") {
          const pageAsNumber = parseInt(parsedUrlQuery.page, 10);

          const pageAsNumberIsValid = Number.isInteger(pageAsNumber) && pageAsNumber > 1;
          if (pageAsNumberIsValid) {
            setPage(pageAsNumber);
            return;
          }
        }
      }

      throw new Error(`Failed to parse next url:${data.next}`);
    }
  };

  const renderItemFunction = ({
    item: generatedRecipeMeal,
    index,
  }: {
    item: GeneratedRecipeMeal;
    index: number;
  }): JSX.Element => (
    <MemoizedRecipeMealCard
      recipeMeal={generatedRecipeMeal}
      onButtonPress={() => onRecipeMealPress(generatedRecipeMeal)}
      onOpenAction={onOpenActionSheet}
      mealSlotSpecification={mealSlotSpecification}
      index={index}
    />
  );

  return (
    <SafeAreaView style={commonStyles.container} nativeID="recipeSearchScreen">
      <FlatList
        ListHeaderComponent={headerComponent}
        ListFooterComponent={loadingAnotherPage ? <Spinner /> : null}
        data={searchResultsList}
        style={{ backgroundColor: "white" }}
        onEndReached={onEndReachedFunc}
        onEndReachedThreshold={0.2}
        bounces
        renderItem={renderItemFunction}
        keyExtractor={(item) => String(item.recipe_template.id)}
        showsVerticalScrollIndicator={false}
        ListEmptyComponent={() => (
          <>
            <Center>
              {isFetching || isLoadingFirstTime ? (
                <Spinner color="primary.600" />
              ) : (
                <Text>{t("general.no_search_results")}</Text>
              )}
            </Center>
          </>
        )}
        keyboardShouldPersistTaps="handled"
        testID={"recipeResults-list"}
        nativeID="recipeResults-list"
      />
    </SafeAreaView>
  );
};
export default RecipeSearchScreen;
