import _ from "lodash";
import { parse } from "tldts";

import { PRODUCTION, SUBDOMAIN_OVERRIDE } from "../constants";
import type {
  DjstripeCustomer,
  DjstripeSubscription,
  DjstripeSubscriptionStatusEnum,
  ExerciseTemplate,
  Organisation,
  User,
  UserDislikeRecipe,
  UserFavouriteRecipe,
} from "../services/backendTypes";
import type {
  UserDislikeRecipeByRecipeTemplateId,
  UserFavouriteRecipeByRecipeTemplateId,
  UserSleepsByDate,
  UserStressByDate,
  UserWeightsByDate,
} from "../slices/userSlice";

// eslint-disable-next-line import/prefer-default-export
export function isUserLoggedIn(user: User | null): boolean {
  return Boolean(user);
}

export function getFavouritedRecipe(
  userFavouriteRecipeByRecipeTemplateId: UserFavouriteRecipeByRecipeTemplateId,
  recipeTemplateId: number
): UserFavouriteRecipe | undefined {
  return userFavouriteRecipeByRecipeTemplateId[recipeTemplateId];
}

export function getDislikedRecipe(
  userDislikeRecipeByRecipeTemplateId: UserDislikeRecipeByRecipeTemplateId,
  recipeTemplateId: number
): UserDislikeRecipe | undefined {
  return userDislikeRecipeByRecipeTemplateId[recipeTemplateId];
}

export const getLatestMeasurementForDate = (
  measurements: UserWeightsByDate | UserSleepsByDate | UserStressByDate,
  date: string
): number | undefined => {
  const measurementOnDate = measurements[date];

  if (!measurementOnDate) return undefined;

  const orderedMeasurements = _.orderBy(measurementOnDate, "created_at", "desc");

  const measurement = _.head(orderedMeasurements);

  if (!measurement) {
    return undefined;
  }

  if (typeof measurement === "number") {
    return measurement;
  }

  // The compiler is acting strangely here and we override it
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const measurementValue = measurement.value;

  switch (measurementValue) {
    case "VERY_LOW":
      return 1;
    case "LOW":
      return 2;
    case "MEDIUM":
      return 3;
    case "HIGH":
      return 4;
    case "VERY_HIGH":
      return 5;
    default:
      return measurementValue;
  }
};

export function getOrganisation(user: User): Organisation | undefined {
  return user?.coach?.organisation || user?.organisation;
}

export function getKcalPerSession(
  exerciseTemplateIdRaw: number | string,
  minutesPerSession: number,
  weight: number,
  exerciseTemplates?: ExerciseTemplate[]
): number {
  const exerciseTemplateId = Number(exerciseTemplateIdRaw);
  const exerciseTemplate = _.find(exerciseTemplates, { id: exerciseTemplateId });

  if (!exerciseTemplate || typeof exerciseTemplate === "number") return 0;

  const kcalPerMin: number = exerciseTemplate.kcal_per_kg_per_min;

  return kcalPerMin * weight * minutesPerSession;
}

export const getUserSubscriptionStatus = (user: User): DjstripeSubscriptionStatusEnum[] | undefined => {
  let customer: DjstripeCustomer | undefined;

  if (user?.is_coach) {
    customer = _.first(user.customer);
  } else {
    return undefined;
  }

  const statuses = _.map(customer?.subscriptions, "status");

  if (_.isEmpty(statuses)) {
    return undefined;
  }

  return statuses;
};

const INVALID_SUBSCRIPTION_STATUSES: DjstripeSubscriptionStatusEnum[] = [
  "canceled",
  "unpaid",
  "incomplete_expired",
  // Note: The status "paused" is still beta (but we use it in production) and not supported by djstripe
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  "paused",
];

export const userAccountIsDisabledOrHasInValidSubscription = (user: User | null): boolean => {
  if (!user) {
    return false;
  }

  if (user.payment_status_override === true) {
    // Always allow the user to login if payment_status_override is set to true
    return false;
  }

  if (!user.account_enabled || (user.coach && !user.coach?.account_enabled)) {
    return true;
  }

  const statuses = getUserSubscriptionStatus(user);

  if (!statuses || _.isEmpty(statuses)) {
    return false;
  }

  const isStatusInvalid = (status: DjstripeSubscriptionStatusEnum): boolean =>
    INVALID_SUBSCRIPTION_STATUSES.includes(status);

  const validStatuses = _.reject(statuses, isStatusInvalid);

  return _.isEmpty(validStatuses);
};

// Stripe subscriptions

/**
 * If customer is trialing (on any subscription)
 */
export function doesCustomerHaveAnyTrialingSubscriptions(user: User): boolean {
  return _.some(user?.customer?.[0]?.subscriptions, { status: "trialing" });
}

const SELF_SERVICE_PRODUCT_STRIPE_ID = "prod_MlNaQY9hwHvdKS";
const SELF_SERVICE_PRODUCT_STRIPE_IDS = [
  // Live mode
  SELF_SERVICE_PRODUCT_STRIPE_ID,
  // Test mode
  "prod_Ml1OptlpTI1FH7",
];
const LEGACY_CUSTOM_BRANDING_PRODUCT_STRIPE_ID = "prod_MlNaQY9hwHvdKS";
const LEGACY_CUSTOM_BRANDING_PRODUCT_STRIPE_IDS = [
  // Live mode
  LEGACY_CUSTOM_BRANDING_PRODUCT_STRIPE_ID,
  // Test mode
  "prod_LYTjPIADGXfoDd",
];

function isCustomerOnOurSelfServicePlan(user: User): boolean {
  // eslint-disable-next-line no-restricted-syntax
  for (const subscription of user?.customer?.[0]?.subscriptions || []) {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of subscription.items) {
      if (item.plan.product && SELF_SERVICE_PRODUCT_STRIPE_IDS.includes(item.plan.product)) {
        return true;
      }
    }
  }

  return false;
}

function doesCustomerHaveValidSubscription(user: User, product: string): boolean {
  // NOTE: The compiler does not work well with lodash filter and typescript
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // const matchingSubscriptions: DjstripeSubscription[] = _.filter(user?.customer?.[0]?.subscriptions, {
  //   plan: { product },
  // });
  const matchingSubscriptions: DjstripeSubscription[] = _.filter(
    user?.customer?.[0]?.subscriptions,
    // (subscription) => subscription?.plan?.product === product
    (subscription) => {
      _.some(subscription?.items, (item) => item.plan.product === product);
    }
  );

  const isAnySubscriptionAValidSubscription = _.some(
    matchingSubscriptions,
    (subscription) => !_.includes(INVALID_SUBSCRIPTION_STATUSES, subscription.status)
  );

  return isAnySubscriptionAValidSubscription;
}

export function shouldCustomerHaveAccessToAccountManagementFunctionality(user: User): boolean {
  return (
    // doesCustomerHaveValidSubscription(user, LEGACY_CUSTOM_BRANDING_PRODUCT_STRIPE_ID) ||
    _.some(LEGACY_CUSTOM_BRANDING_PRODUCT_STRIPE_IDS, (productId) =>
      doesCustomerHaveValidSubscription(user, productId)
    ) || isCustomerOnOurSelfServicePlan(user)
  );
}

export function shouldShowCustomBrandingHeader(user: User): boolean {
  return Boolean(user?.coach.organisation && user?.coach.organisation?.logo_visible);
}

export function isUserDiy(user: User): boolean {
  return Boolean(getOrganisation(user)?.clients_are_diy);
}

export function hasUserCompletedTheirDiyNutritionPlanningFormCompletely(user: User): boolean {
  return Boolean(user?.intake && user?.intake.weekly_nutrition_plan);
}

export const getHostname = (): string => window.location.hostname;

/**
 * We host customer portals on subdomains of our main domain.
 * This function checks if we are on a subdomain of our main domain and
 * therefore should do the logic for a customer portal
 *
 * @param hostname ie, "trainmore.weekmeals.co"
 * @returns
 */
export function getManagedCustomerPortalSubdomain(hostname: string): string {
  if (SUBDOMAIN_OVERRIDE && !PRODUCTION) {
    // This is for development purposes
    return SUBDOMAIN_OVERRIDE;
  }

  const parsedDomain = parse(hostname);

  const { subdomain } = parsedDomain;

  // Ignore PR branch subdomains
  if (subdomain && subdomain.startsWith("pr-") && hostname.includes("amplifyapp.com")) {
    return "";
  }

  if (subdomain === "www" || subdomain === "app") {
    return "";
  }

  return subdomain || "";
}

export enum FeatureFlag {
  // eslint-disable-next-line no-unused-vars
  DisableProductLogging = "feature_flag_disable_product_logging",
  // Add other feature flags here as needed
}

export function isFeatureFlagEnabled(organisation: Organisation | null, flag: FeatureFlag): boolean {
  if (!organisation) return false;
  return _.get(organisation, flag, false);
}

export function doesOrganisationHaveManagedPlanning(organisation: Organisation): boolean {
  return Boolean(organisation?.clients_are_diy);
}
