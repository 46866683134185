import { Entypo } from "@expo/vector-icons";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import _ from "lodash";
import { AlertDialog, Button, useTheme } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button as NativeButton, Text, View } from "react-native";
import { useSelector } from "react-redux";

import ProgressBar from "../commons/ProgressBar";
import { Routes, Scale } from "../constants";
import { formatMomentAsBackendFormatDateString, formatNumberAsWholeNumber, isIos } from "../helpers/generalHelpers";
import {
  doesOrganisationHaveManagedPlanning,
  FeatureFlag,
  getOrganisation,
  isFeatureFlagEnabled,
} from "../helpers/userHelpers";
import type { RootStackParamList } from "../navigation/NavigationStackParams";
import backendApi from "../services/backendApi";
import type {
  CalendarDay,
  CalendarItem,
  CalendarItemStatusEnum,
  Meal,
  MealSlotSpecification,
  RecipeMeal,
  SingleFoodMeal,
} from "../services/backendTypes";
import logger from "../services/logger";
import { currentDayInPlannerSelector } from "../slices/plannerSlice";
import { userSelector, viewAsUserSelector } from "../slices/userSlice";
import styles from "./MacroTargetInfoStyle";
import style from "./MealsCategoryCardStyles";
import CalendarItemComponent from "./PlannerItem";

const {
  usePlannerCalendarItemDestroyMutation,
  usePlannerCalendarItemPartialUpdateMutation,
  useFoodIngredientPartialUpdateMutation,
  usePlannerAutoPlanDayCreateMutation,
} = backendApi;

type Props = {
  title?: string;
  color?: string;
  actualValue: number;
  targetValue: number;
  currentCalendarDay: CalendarDay;
  calendarItems?: CalendarItem[];
  mealSlotSpecification: MealSlotSpecification;
  navigation: NativeStackNavigationProp<RootStackParamList, Routes.DiaryViewScreen>;
  onPressPlannedStatus: (arg0: number, arg1?: string) => void;
  refetchCalendarDays: () => void;
  onPressAddMoreComponent: () => void;
  onPressPlanNewMeal: () => void;
  onPressMenu: () => void;
};

const MealSlotInPlanner = ({
  title,
  color,
  actualValue,
  targetValue,
  currentCalendarDay,
  calendarItems,
  mealSlotSpecification,
  navigation,
  onPressPlannedStatus,
  refetchCalendarDays,
  onPressAddMoreComponent,
  onPressPlanNewMeal,
  onPressMenu,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const theme = useTheme();

  const [autoPlanDay, { isLoading: isLoadingAutoPlanDay }] = usePlannerAutoPlanDayCreateMutation();
  const currentDayInPlanner = useSelector(currentDayInPlannerSelector);
  const viewAsUser = useSelector(viewAsUserSelector);
  const realUser = useSelector(userSelector);
  const user = viewAsUser || realUser;

  const organisation = user ? getOrganisation(user) : null;
  const productLoggingIsDisabled = isFeatureFlagEnabled(organisation || null, FeatureFlag.DisableProductLogging);

  const [updateCalendarItem, { isLoading: isLoadingUpdateCalendarItem }] =
    usePlannerCalendarItemPartialUpdateMutation();
  const [updateSingleFoodMealIngredient, { isLoading: isLoadingUpdateSingleFoodMealIngredient }] =
    useFoodIngredientPartialUpdateMutation();

  const [calendarItemToDeleteId, setCalendarItemToDeleteId] = React.useState<number | undefined>(undefined);
  const [isOpenDeleteMenu, setIsOpenDeleteMenu] = React.useState(false);

  const createPlannerItemFromCalendarItem = ({
    content_type: contentType,
    meal,
    meal_slot: mealSlot,
    status,
    id,
  }: CalendarItem): JSX.Element => {
    const onPressCalendarItemPlannedStatus = async (): Promise<void> => {
      if (!id) {
        throw new Error("id is undefined");
      }
      if (!meal.id) {
        throw new Error("meal.id is undefined");
      }

      onPressPlannedStatus(meal.id, title);

      const newStatus: CalendarItemStatusEnum = status === "PLANNED" ? "EATEN" : "PLANNED";
      await updateCalendarItem({ id, patchedCalendarItemRequest: { status: newStatus } }).unwrap();
      return refetchCalendarDays();
    };

    const onSwapCalendarItem = async (): Promise<void> => {
      // Note:  We only swap recipemeals
      if (contentType !== "recipemeal") {
        return;
      }

      if (!user) {
        throw new Error("user is undefined");
      }

      await autoPlanDay({
        autoPlannerRequest: {
          date: formatMomentAsBackendFormatDateString(currentDayInPlanner),
          user: user.id,
          meal_slot_ids: [mealSlot],
        },
      });
    };

    const onPressCalendarItem = (): void => {
      if (contentType === "recipemeal") {
        const recipeMealEditable = !isFeatureFlagEnabled(organisation || null, FeatureFlag.DisableProductLogging);

        navigation.push(Routes.AddRecipeTemplateStack, {
          // NOTE: We are probably defining the screen types wrong
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          screen: Routes.RecipeDetailScreen,
          params: {
            recipeMeal: meal,
            mealSlotSpecification,
            mealType: "RecipeMeal",
            viewOnly: true,
            editable: recipeMealEditable,
          },
        });
      } else if (contentType === "singlefoodmeal") {
        if (!(meal as SingleFoodMeal).ingredient || !(meal as SingleFoodMeal).ingredient.id) {
          throw new Error("meal.ingredient.id is undefined");
        }

        navigation.push(Routes.AddProductStack, {
          screen: Routes.AddIngredientScreen,
          params: {
            onChoose: async (product, suggestedServing, quantity) => {
              await updateSingleFoodMealIngredient({
                // We explicitly check that id is not undefined above
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                id: (meal as SingleFoodMeal).ingredient.id,
                patchedIngredientRequest: {
                  suggested_serving: suggestedServing,
                  quantity,
                },
              }).unwrap();
              navigation.pop(1);
              refetchCalendarDays();
            },
            ingredient: (meal as SingleFoodMeal).ingredient,
          },
        });
      }
    };

    const handleListAlternativesPress = (): void => {
      navigation.push(Routes.RecipeSearchScreen, {
        mealSlotSpecification,
        currentCalendarDay,
        calendarItemToReplace: id,
      });
    };

    const onLongPressCalendarItem = (): void => {
      logger.debug("onLongPressCalendarItem");
      setIsOpenDeleteMenu(true);

      setCalendarItemToDeleteId(id);
    };

    // TODO: Memoize this (but it is difficult to use the useCallback
    // hook because we are inside a function (not a react component))
    return (
      <CalendarItemComponent
        key={id}
        meal={meal}
        onPress={onPressCalendarItem}
        onSwap={onSwapCalendarItem}
        onLongPress={onLongPressCalendarItem}
        onListAlternativesPress={() => handleListAlternativesPress()}
        isLoading={isLoadingAutoPlanDay || isLoadingUpdateCalendarItem}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onPressCalendarItemPlannedStatus={onPressCalendarItemPlannedStatus}
        status={status || "PLANNED"}
        contentType={contentType}
        editable={!productLoggingIsDisabled}
      />
    );
  };
  const [deleteCalendarItemOnBackend, { isLoading: isLoadingDeleteCalendarItemOnBackend }] =
    usePlannerCalendarItemDestroyMutation();
  const deleteCalendarItem = async (calendarItemId: number): Promise<void> => {
    if (!calendarItemId) {
      throw new Error("calendarItemId is undefined");
    }

    if (calendarItemId) {
      await deleteCalendarItemOnBackend({ id: calendarItemId }).unwrap();
      setIsOpenDeleteMenu(false);
      refetchCalendarDays();
    }
  };

  const onClose = (): void => setIsOpenDeleteMenu(false);
  const cancelRef = React.useRef(null);
  const deleteMealComponent = (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={isOpenDeleteMenu}
      onClose={onClose}
      testID={"delete-planned-meal-modal"}
    >
      <AlertDialog.Content>
        <AlertDialog.CloseButton />
        <AlertDialog.Header>{t("planner.delete_meal_confirm_button_text")}</AlertDialog.Header>
        <AlertDialog.Footer>
          <Button.Group space={2}>
            <Button variant="unstyled" colorScheme="coolGray" onPress={onClose} ref={cancelRef}>
              {t("general.cancel")}
            </Button>
            <Button
              isLoading={isLoadingDeleteCalendarItemOnBackend}
              colorScheme="danger"
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onPress={async () => {
                if (!calendarItemToDeleteId) throw new Error("calendarItemToDeleteId is undefined");
                await deleteCalendarItem(calendarItemToDeleteId);
              }}
              testID="delete-planned-meal-modal-confirm-button"
            >
              {t("general.delete")}
            </Button>
          </Button.Group>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );

  const disablePlanMealAction = !currentCalendarDay;

  const planMealComponent = (
    <View
      style={isIos() ? { backgroundColor: theme.colors.primary["400"] } : {}}
      nativeID={`planMealComponent-${mealSlotSpecification.meal_moment}`}
    >
      <NativeButton
        title={t("planner.plan_meal_button_text")}
        onPress={onPressPlanNewMeal}
        color={isIos() ? "white" : theme.colors.primary["400"]}
        testID={`planMeal-${mealSlotSpecification.meal_moment}`}
        disabled={disablePlanMealAction}
      />
    </View>
  );

  // TODO: memo() or useCallback()?
  const renderItemFunction = (index: number, calendarItem: CalendarItem): JSX.Element =>
    createPlannerItemFromCalendarItem(calendarItem);

  // TODO: We should consider sorting by created_at but this property does not exist on the model
  const headerComponent = (
    <View style={style.textContainer}>
      <>
        <View style={[style.titleDiv, { alignItems: "center" }]}>
          <Text style={style.head}>{title}</Text>
          <Entypo
            onPress={onPressMenu}
            testID={`pressMealMomentMenu-${mealSlotSpecification.meal_moment}`}
            nativeID={`pressMealMomentMenu-${mealSlotSpecification.meal_moment}`}
            name="dots-three-horizontal"
            size={24}
            color="black"
            style={{ marginLeft: Scale(8) }}
          />
        </View>
      </>
      {/* NOTE: This View is necessary for the styling */}
      <View>
        <View style={style.nutrientTextDiv}>
          <Text style={{ ...style.nutrientText1, color: "black" }}>{formatNumberAsWholeNumber(actualValue)}</Text>
          <Text style={{ ...style.nutrientText2, color: theme.colors.gray["400"] }}>
            /{formatNumberAsWholeNumber(targetValue)} {t("general.kcal")}
          </Text>
        </View>
        <View style={styles.progressBarDiv}>
          <ProgressBar value={(actualValue / targetValue) * 100} />
        </View>
      </View>
    </View>
  );

  const sortedCalendarItems = _.sortBy(calendarItems, [(item) => (item.content_type === "recipemeal" ? 0 : 1), "id"]);

  return (
    <View style={style.mainContainer} nativeID={`mealSlot-${mealSlotSpecification.meal_moment}`}>
      {headerComponent}
      {!_.isEmpty(calendarItems) ? (
        <>
          {sortedCalendarItems.map((item, index) => renderItemFunction(index, item))}

          {organisation && doesOrganisationHaveManagedPlanning(organisation) ? null : (
            <View style={isIos() ? { backgroundColor: theme.colors.primary["300"] } : {}}>
              <NativeButton
                title={t("planner.add_more_button_text")}
                onPress={onPressAddMoreComponent}
                color={isIos() ? "white" : theme.colors.primary["300"]}
                testID={`addMoreButton-${mealSlotSpecification.meal_moment}`}
              />
            </View>
          )}
        </>
      ) : (
        planMealComponent
      )}
      {deleteMealComponent}
    </View>
  );
};

export default MealSlotInPlanner;
