import { MaterialCommunityIcons } from "@expo/vector-icons";
import type { NavigationProp, ParamListBase } from "@react-navigation/native";
import { Icon } from "native-base";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dimensions, ScrollView, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Button, useTheme } from "react-native-elements";

import { Routes } from "../../constants";

const { width: viewportWidth } = Dimensions.get("window");

interface PostOnboardingScreenProps {
  navigation: NavigationProp<ParamListBase>;
}

type SlideItem = {
  title: string;
  description: string;
  icon: string;
};

const PostOnboardingScreen: React.FC<PostOnboardingScreenProps> = ({ navigation }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const primaryColor = theme.colors?.primary;

  const scrollViewRef = useRef<ScrollView>(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const slides: SlideItem[] = [
    {
      title: t("onboarding.onboarding_11_post_onboarding_screen.carousel_slide_1_title"),
      description: t("onboarding.onboarding_11_post_onboarding_screen.carousel_slide_1_description"),
      icon: "check-circle-outline",
    },
    {
      title: t("onboarding.onboarding_11_post_onboarding_screen.carousel_slide_2_title"),
      description: t("onboarding.onboarding_11_post_onboarding_screen.carousel_slide_2_description"),
      icon: "food-variant",
    },
    {
      title: t("onboarding.onboarding_11_post_onboarding_screen.carousel_slide_3_title"),
      description: t("onboarding.onboarding_11_post_onboarding_screen.carousel_slide_3_description"),
      icon: "cart-outline",
    },
    {
      title: t("onboarding.onboarding_11_post_onboarding_screen.carousel_slide_4_title"),
      description: t("onboarding.onboarding_11_post_onboarding_screen.carousel_slide_4_description"),
      icon: "chef-hat",
    },
  ];

  const handleScroll = (event: { nativeEvent: { contentOffset: { x: number } } }): void => {
    const slideIndex = Math.floor(event.nativeEvent.contentOffset.x / viewportWidth);
    setActiveSlide(slideIndex);
  };

  const goToNextSlide = (): void => {
    if (activeSlide < slides.length - 1) {
      scrollViewRef.current?.scrollTo({
        x: viewportWidth * (activeSlide + 1),
        animated: true,
      });
      setActiveSlide(activeSlide + 1);
    }
  };

  const goToPreviousSlide = (): void => {
    if (activeSlide > 0) {
      scrollViewRef.current?.scrollTo({
        x: viewportWidth * (activeSlide - 1),
        animated: true,
      });
      setActiveSlide(activeSlide - 1);
    }
  };

  const renderSlide = (item: SlideItem, index: number): JSX.Element => (
    <View key={index} style={styles.slide}>
      <Icon
        as={MaterialCommunityIcons}
        name={item.icon}
        size={40}
        color={theme.colors?.primary}
        tvParallaxProperties={undefined}
      />
      <Text style={styles.title}>{item.title}</Text>
      <Text style={styles.description}>{item.description}</Text>
    </View>
  );

  const renderPagination = (): JSX.Element => (
    <View style={styles.pagination}>
      {slides.map((_, index) => (
        <View key={index} style={[styles.dot, activeSlide === index ? styles.activeDot : styles.inactiveDot]} />
      ))}
    </View>
  );

  const handleProceed = (): void => {
    if (activeSlide === slides.length - 1) {
      navigation.navigate(Routes.BottomNavigationTab, { screen: Routes.DiaryViewScreen });
    } else {
      goToNextSlide();
    }
  };

  const getButtonTitle = (): string => {
    if (activeSlide === 0) {
      return t("onboarding.onboarding_11_post_onboarding_screen.start_tour");
    }
    if (activeSlide === slides.length - 1) {
      return t("onboarding.onboarding_11_post_onboarding_screen.proceed_button");
    }
    return t("general.next_button_text");
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Icon name="arrow-back" size={24} onPress={() => navigation.goBack()} />
      </View>

      <ScrollView
        ref={scrollViewRef}
        horizontal
        pagingEnabled
        showsHorizontalScrollIndicator={false}
        onScroll={handleScroll}
        scrollEventThrottle={16}
        contentContainerStyle={{ width: `${100 * slides.length}%` }}
        style={styles.carousel}
      >
        {slides.map((item, index) => renderSlide(item, index))}
      </ScrollView>

      {renderPagination()}

      {activeSlide > 0 && (
        <TouchableOpacity style={styles.leftArrow} onPress={goToPreviousSlide}>
          <Text style={styles.arrowText}>‹</Text>
        </TouchableOpacity>
      )}
      {activeSlide < slides.length - 1 && (
        <TouchableOpacity style={styles.rightArrow} onPress={goToNextSlide}>
          <Text style={styles.arrowText}>›</Text>
        </TouchableOpacity>
      )}

      <Button
        title={getButtonTitle()}
        buttonStyle={[styles.proceedButton, { backgroundColor: primaryColor }]}
        onPress={handleProceed}
        testID="proceed-button"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#f9f9f9",
    justifyContent: "space-between",
    alignItems: "center",
    width: viewportWidth,
    paddingBottom: 40,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    padding: 16,
    width: "100%",
  },
  carousel: {
    width: viewportWidth,
    marginTop: 100,
  },
  slide: {
    width: viewportWidth,
    padding: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: 200,
    height: 200,
    backgroundColor: "#f0f0f0",
  },
  title: {
    fontSize: 24,
    fontWeight: "600",
    marginTop: 16,
    color: "#333",
    textAlign: "center",
  },
  description: {
    fontSize: 16,
    color: "#666",
    textAlign: "center",
    marginTop: 8,
    paddingHorizontal: 16,
  },
  pagination: {
    flexDirection: "row",
    alignItems: "center", // Center items vertically in row
    justifyContent: "center", // Center the pagination horizontally
    marginTop: 16,
    marginBottom: 20,
  },
  dot: {
    width: 10,
    height: 10,
    borderRadius: 5,
    marginHorizontal: 4,
  },
  activeDot: {
    backgroundColor: "#4E5DDB",
  },
  inactiveDot: {
    backgroundColor: "#ccc",
  },
  leftArrow: {
    position: "absolute",
    top: "50%",
    left: 16,
  },
  rightArrow: {
    position: "absolute",
    top: "50%",
    right: 16,
  },
  arrowText: {
    fontSize: 32,
    color: "#666",
  },
  proceedButton: {
    paddingVertical: 14,
    paddingHorizontal: 32,
    marginBottom: 40,
    borderRadius: 8,
    width: "80%",
    elevation: 2,
    alignSelf: "center", // Center the button horizontally
  },
});

export default PostOnboardingScreen;
