import { AntDesign, MaterialIcons } from "@expo/vector-icons";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import _ from "lodash";
// eslint-disable-next-line import/no-named-default
import { default as MomentLib } from "moment";
import { extendMoment } from "moment-range";
import {
  AlertDialog,
  Button,
  Icon,
  IconButton,
  Modal,
  Pressable,
  Row,
  ScrollView,
  Spinner,
  Text,
  useDisclose,
  useTheme,
  View,
} from "native-base";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { SafeAreaView } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import CheckBoxCom from "../components/checkBox";
import CustomBrandingMobileScreenHeader from "../components/CustomBrandingMobileScreenHeader";
import GroceryListDatePicker from "../components/GroceryListDatePicker";
import { commonStyles, isDesktopScreen, Routes, Scale } from "../constants";
import { formatMomentAsDateForApi } from "../helpers/apiHelpers";
import { getGroceryListItemInWeight, shouldWeUseImperialForThisUser } from "../helpers/foodHelpers";
import { formatDate } from "../helpers/generalHelpers";
import type { RootStackParamList } from "../navigation/NavigationStackParams";
import backendApi from "../services/backendApi";
import type { CategoryEnum, GroceryListItemUpdate as GroceryListItem } from "../services/backendTypes";
import { groceryListSelector, plannerSlice } from "../slices/plannerSlice";
import { userSelector } from "../slices/userSlice";
import styles from "./GroceriesViewScreenStyle";

const {
  usePlannerGroceryListCreateMutation,
  usePlannerGroceryListDestroyMutation,
  usePlannerGroceryListItemPartialUpdateMutation,
  usePlannerGroceryListListQuery,
} = backendApi;

// NOTE: This is an internal type error with MomentLib
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const moment = extendMoment(MomentLib);

type Props = NativeStackScreenProps<RootStackParamList, Routes.GroceryListScreen>;

const GroceryListScreen = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  // TODO: This functionality is not currently used but when it is this hook should be called safely
  // const user = useSelector(viewAsUserSelector) || useSelector(userSelector);
  const user = useSelector(userSelector);

  const isDesktop = isDesktopScreen();

  const { onOpen, isOpen, onClose } = useDisclose();
  const cancelRef = React.useRef(null);

  const theme = useTheme();

  const groceryLists = useSelector(groceryListSelector);

  const {
    isOpen: isOpenGroceryListDatePicker,
    onOpen: onOpenGroceryListDatePicker,
    onClose: onCloseGroceryListDatePicker,
  } = useDisclose();

  if (!user) {
    throw new Error("User is not set");
  }

  // NOTE: We assume the user only ever has 1 grocery list at a time and we always fetch the last created
  const groceryList = _.chain(groceryLists).sortBy("id").reverse().head().value();

  const [updateGroceryListItemOnBackend, { isLoading: isLoadingUpdateGroceryListItem }] =
    usePlannerGroceryListItemPartialUpdateMutation();
  const {
    data: groceryListsDataFromApi,
    refetch: refetchGroceryLists,
    isLoading: isLoadingGroceryLists,
  } = usePlannerGroceryListListQuery({
    user: user.id,
  });

  useEffect(() => {
    if (groceryListsDataFromApi?.results) {
      dispatch(plannerSlice.actions.setGroceryLists(groceryListsDataFromApi?.results));
    }
  }, [groceryListsDataFromApi]);

  const toggleCheckedStatusOnGroceryListItem = async (item: GroceryListItem): Promise<void> => {
    if (!item.id) {
      throw new Error("Grocery list item id is not defined");
    }

    await updateGroceryListItemOnBackend({
      id: item.id,
      patchedGroceryListItemUpdateRequest: {
        checked: !item.checked,
      },
    });
  };

  const onPressGroceryListItem = async (item: GroceryListItem): Promise<void> => {
    await toggleCheckedStatusOnGroceryListItem(item);
    refetchGroceryLists();
  };

  const markAllAsChecked = async (): Promise<void> => {
    const allUncheckedItems = _.reject(groceryList?.items, "checked");

    await Promise.all(allUncheckedItems.map(toggleCheckedStatusOnGroceryListItem));

    refetchGroceryLists();
  };

  const [createGroceryListOnBackend, { isLoading: isLoadingCreateGroceryListOnBackend }] =
    usePlannerGroceryListCreateMutation();
  const [deleteGroceryListOnBackend, { isLoading: isLoadingDeleteGroceryList }] =
    usePlannerGroceryListDestroyMutation();

  const onPressCreateGroceryListButton = (): void => onOpenGroceryListDatePicker();

  const createGroceryList = async (startDate: moment.Moment, endDate: moment.Moment): Promise<void> => {
    await createGroceryListOnBackend({
      groceryListUpdateCreateRequest: {
        start_date: formatMomentAsDateForApi(startDate),
        end_date: formatMomentAsDateForApi(endDate),
        user: user.id,
      },
    });

    onCloseGroceryListDatePicker();
    refetchGroceryLists();
  };

  const deleteGroceryList = async (): Promise<void> => {
    onClose();

    await deleteGroceryListOnBackend({
      id: groceryList.id,
    });
    refetchGroceryLists();
  };
  const shouldUseImperial = shouldWeUseImperialForThisUser(user);
  const createGroceryListItemComponent = (item: GroceryListItem): JSX.Element => (
    <View key={item.id} style={styles.listMainView}>
      <Pressable
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onPress={async () => onPressGroceryListItem(item)}
        testID={`grocery-list-item-${item.checked ? "checked" : "unchecked"}-${item.id}`}
      >
        <Row>
          {!item.checked ? (
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            <CheckBoxCom checked={item.checked || false} onSelect={async () => onPressGroceryListItem(item)} />
          ) : (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <AntDesign
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onPress={async () => onPressGroceryListItem(item)}
              name="checksquare"
              size={28}
              style={{ paddingRight: 14 }}
              color={theme.colors.primary["600"]}
            />
          )}
          <View>
            <View>
              <Text bold color={item.checked ? "primary.600" : "gray.400"} strikeThrough={item.checked}>
                {item.name}
              </Text>
            </View>
            <Text color={item.checked ? "primary.600" : "gray.400"} strikeThrough={item.checked}>
              {getGroceryListItemInWeight(item, shouldUseImperial)}
            </Text>
          </View>
        </Row>
      </Pressable>
    </View>
  );

  const allGroceryItems = groceryList?.items || [];
  const groupedAllGroceryItems = _.groupBy(
    allGroceryItems,
    (groceryListItem): CategoryEnum => groceryListItem?.category || "OTHER"
  );

  const groceryItems = (
    <>
      {allGroceryItems.length > 0 && (
        <View style={styles.headerContainer}>
          <Row>
            {/* <Text style={styles.headerTextStyle} color="gray.300">
              {t("bottom_tabs.groceries_tab_name")}
            </Text> */}
            {isLoadingUpdateGroceryListItem ? <Spinner ml="2" /> : null}
          </Row>
        </View>
      )}
      {Object.keys(groupedAllGroceryItems).map((category) => (
        <View key={category}>
          <Text style={styles.headerTextStyle} color="gray.300" mt={3}>
            {t(`general.grocery_categories.${category as CategoryEnum}`)}
          </Text>
          {_.get(groupedAllGroceryItems, category).map(createGroceryListItemComponent)}
        </View>
      ))}
    </>
  );

  const markAllCheckedButton = (
    <View style={{ marginTop: Scale(50), marginBottom: Scale(48) }}>
      <Button
        variant="outline"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onPress={markAllAsChecked}
        testID={"markAllChecked-button"}
      >
        {t("grocery_list.mark_all_as_checked_button_text")}
      </Button>
    </View>
  );

  const datePickerModal = (
    <Modal isOpen={isOpenGroceryListDatePicker} onClose={onCloseGroceryListDatePicker} size="xl">
      <Modal.Content bgColor={"white"}>
        <Modal.CloseButton />
        <Modal.Header fontSize="4xl" fontWeight="bold" bgColor={"white"}>
          {t("grocery_list.select_date_range_label_text")}
        </Modal.Header>
        <Modal.Body>
          <GroceryListDatePicker createGroceryList={createGroceryList} />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );

  const createGroceryListButtonComponent = (
    <View>
      <Button
        disabled={isLoadingCreateGroceryListOnBackend}
        onPress={onPressCreateGroceryListButton}
        testID={"createGroceryList-button"}
      >
        {t("grocery_list.create_new_grocery_list_button_text")}
      </Button>
    </View>
  );
  const areAllItemsChecked = _.every(allGroceryItems, "checked");
  const listOfGroceriesComponent = (
    <>
      <View>
        <Row>
          <Text fontSize={"2xl"} style={{ justifyContent: "center" }}>
            {formatDate(moment(groceryList?.start_date))}
            {"  —  "}
            {formatDate(moment(groceryList?.end_date))}
          </Text>

          {isLoadingDeleteGroceryList ? (
            <Spinner ml="2" />
          ) : (
            <IconButton
              icon={<Icon as={MaterialIcons} color={"primary.600"} name="delete" />}
              onPress={onOpen}
              testID={"deleteGroceryList-button"}
            />
          )}
        </Row>
      </View>

      {allGroceryItems.length === 0 ? (
        <Text>{t("grocery_list.this_grocery_list_is_empty_label_text")}</Text>
      ) : (
        <>
          {areAllItemsChecked ? (
            <View style={styles.allDoneView} bgColor="primary.200" borderColor="primary.600">
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <AntDesign name="checksquare" size={24} color={theme.colors.primary["600"]} />
              <Text fontSize="2xl" color="primary.600">
                {t("grocery_list.all_done_label")}
              </Text>
            </View>
          ) : null}
          {/* NOTE: If we want to have grocery categories (ie, the different aisles, bread, dairy, vegetable)
then we need to populate the food field on the suggested_serving on the serializer.
I suggest we leave this for now. */}
          {groceryItems}
          {!areAllItemsChecked && markAllCheckedButton}
        </>
      )}
    </>
  );
  const groceryListComponent = (
    <View>
      <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={{ flexGrow: 1, padding: 20 }}>
        <View style={styles.partitionLine} />

        {_.isEmpty(groceryLists) ? (
          <View style={styles.emptyStateContainer}>
            <Icon as={MaterialIcons} color={"primary.600"} name="shopping-cart" size={20} />
            <Text style={styles.emptyStateText}>{t("grocery_list.empty_state_message")}</Text>
          </View>
        ) : (
          listOfGroceriesComponent
        )}
        {createGroceryListButtonComponent}
      </ScrollView>
    </View>
  );

  const deleteConfirmationDialog = (
    <AlertDialog leastDestructiveRef={cancelRef} isOpen={isOpen}>
      <AlertDialog.Content>
        <AlertDialog.CloseButton onPress={onClose} />
        <AlertDialog.Body bgColor="white">
          <Text>{t("general.dialog_confirmation_question")}</Text>
        </AlertDialog.Body>
        <AlertDialog.Footer bgColor="white">
          <Button
            isLoading={isLoadingDeleteGroceryList}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onPress={deleteGroceryList}
            testID={"confirmDeleteGroceryList-button"}
          >
            {t("general.delete")}
          </Button>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );

  return (
    <SafeAreaView style={commonStyles.container}>
      <ScrollView showsVerticalScrollIndicator={false}>
        {!isDesktop && <CustomBrandingMobileScreenHeader />}
        <>
          <View style={[styles.container, styles.headerContainer]}>{groceryListComponent}</View>
          {datePickerModal}
        </>
      </ScrollView>
      {deleteConfirmationDialog}
    </SafeAreaView>
  );
};
export default GroceryListScreen;
